import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  List,
  MenuProps,
  Modal,
  Popover,
  Space,
  Tooltip,
  message,
} from 'antd';
import { useCompany } from '../../hooks/company';
import {
  BellOutlined,
  WarningOutlined,
  PauseCircleOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { loginController, systemController, userController } from '../../api';
import UserMessage, { HistoryMessage } from './UserMessage';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import '../../styles/common.scss';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { AvatarImg } from '../OSSImage';
import SuperAdminCompany from '../SuperAdminCompany';

const CompanySwitchList = () => {
  const { id: companyId } = useCompany();

  const { data: companyList, loading: listCompanyLoading } = useRequest(() => {
    return systemController.listCompany();
  }, {});

  const switchCompany = (id: I.ID) => {
    window.location.href = `/${id}/welcome`;
  };

  return (
    <List loading={listCompanyLoading}>
      {companyList?.map((company) => {
        return (
          <List.Item
            key={company.id}
            actions={[
              <Button
                disabled={companyId === company.id}
                type="primary"
                onClick={() => switchCompany(company.id)}
              >
                切换
              </Button>,
            ]}
          >
            {company.name}
          </List.Item>
        );
      })}
    </List>
  );
};

const CompanySwitch = ({
  open,
  onCancel,
}: {
  open?: boolean;
  onCancel: () => void;
}) => {
  const { currentUser, hasPermission } = useCompany();
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onCancel}
      title="切换公司"
      footer={false}
    >
      {currentUser.isSuperAdmin || !currentUser.companyId ? (
        <SuperAdminCompany />
      ) : (
        <CompanySwitchList />
      )}
    </Modal>
  );
};

const UserInfo = () => {
  const { currentUser, id: companyId } = useCompany();
  const [backgroundColor, setBackgroundColor] = useState<string>();

  useEffect(() => {
    if (currentUser.id) {
      Sentry.setUser({
        id: `${window.location.host}-${currentUser.id}`,
        username: currentUser.name,
      });
    }
  }, [currentUser]);

  const navigate = useNavigate();
  const { run: logout, loading } = useRequest(
    () => {
      return loginController.logout();
    },
    {
      manual: true,
      onSuccess: () => {
        message.success({
          content: '退出成功',
        });
        window.location.reload();
      },
    },
  );

  const [showCompanySwitch, setShowCompanySwitch] = useState<boolean>(false);

  const items = useMemo<MenuProps['items']>(() => {
    const v = [
      {
        key: 'user-info',
        label: '个人信息',
        onClick: () => {
          navigate(`/${companyId}/user-self-info`);
        },
      },
      {
        key: 'logout',
        label: '退出登录',
        onClick: () => {
          logout();
        },
      },
    ];
    if (currentUser.isSuperAdmin || !currentUser.companyId) {
      v.unshift({
        label: '切换公司',
        key: 'switch-company',
        onClick: () => {
          setShowCompanySwitch(true);
        },
      });
    }
    return v;
  }, []);
  return (
    <>
      {(currentUser.userConfig?.newCustomerStatus ||
        !currentUser.userConfig?.newCustomerLimit) && (
        <Tooltip
          overlayClassName="custom-tooltips"
          title="暂停接单"
          color={'#fff'}
          style={{ color: '#333' }}
        >
          <PauseCircleOutlined
            style={{ cursor: 'pointer', fontSize: '18px', color: 'orange' }}
            twoToneColor="red"
          />
        </Tooltip>
      )}
      <Dropdown
        disabled={loading}
        menu={{
          items,
        }}
        placement="bottom"
      >
        <span
          style={{
            cursor: 'default',
            backgroundColor,
            height: '64px',
            padding: '0 20px',
            lineHeight: '64px',
            display: 'inline-block',
          }}
          onMouseEnter={() => setBackgroundColor('#f9f9f9')}
          onMouseLeave={() => setBackgroundColor(undefined)}
        >
          <Space>
            <AvatarImg userId={currentUser.id} icon={<UserOutlined />} />
            <span>{currentUser.name}</span>
            <DownOutlined />
          </Space>
        </span>
      </Dropdown>
      <CompanySwitch
        open={showCompanySwitch}
        onCancel={() => setShowCompanySwitch(false)}
      />
    </>
  );
};

export default () => {
  return (
    <Space style={{ marginRight: '20px', fontSize: '16px' }}>
      <span style={{ paddingRight: '10px' }}>
        <HistoryMessage />
      </span>
      <span style={{ paddingRight: '10px' }}>
        <UserMessage />
      </span>
      <UserInfo />
    </Space>
  );
};
